
import '../styles/header.scss';
import parse from 'html-react-parser';

const Header = ({ me, title, titleColor, instructions, workshop}) => {
	const greeting = <p className='worksheet-header-greeting'>{`Welcome, ${me && me.first_name}!`} </p>;
	const instructionsText = <div className='worksheet-header-instructions'>{ instructions ? parse(instructions) : ""}</div>
	
	return (
		<div className='worksheet-header'>
			<h1 style={{ color: titleColor }} className="worksheet-header-text">{title}</h1>
			{ me && workshop === 'Journal' && greeting } 
			{ instructions && instructionsText }
		</div>
	);
}

export default Header;