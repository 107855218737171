import React from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Input from './Input';
import '../styles/searchableInput.scss';

class SearchableInput extends React.Component {
	state = {
		matching: [],
		selected: {},
		value: '',
		count: 0
	};

	inputRef = React.createRef();

	componentDidMount = () => {
		const { defaultValue } = this.props;
		if (defaultValue) this.setState({ value: defaultValue });
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { selected, value } = this.state;
		const { data, dataKey, defaultValue, label, onChange } = this.props;
		if (prevState.value !== value) {
			if (!value) this.setState({ matching: [], selected: {} });
			else {
				let num = 0;
				const lcValue = value.toLowerCase();
				const matching = (data || []).filter(a => (
					(a.first_name|| '').toLowerCase().includes(lcValue) || (a.last_name || '').toLowerCase().includes(lcValue)
				));
				const fullMatch = (data || []).find(({ first_name, last_name }) => {
					return value === `${first_name} ${last_name}`;
				});
				num = fullMatch && fullMatch.first_name
				this.setState({ matching, selected: fullMatch, count: num });
			}
		}

		if (prevState.selected !== selected && selected) {
			if (!this.props.value || (prevProps.value.email !== selected.email)) {
				onChange(dataKey || label.toLowerCase(), selected);
			}
		}

		if (prevProps.value !== this.props.value) {
			const { first_name, last_name } = this.props.value;
			if (first_name || last_name) this.handleChange('', `${first_name} ${last_name}`)
		}

		if (prevProps.defaultValue !== defaultValue) {
			this.setState({ value: defaultValue });
		}
	}

	handleBlur = () => {
		setTimeout(() => {
			const { selected } = this.state;
			if (!selected || !selected.email) {
				NotificationManager.warning('Please select a valid person');
				this.setState({ matching: [], value: '' })
			}
		}, 250)
	}

	handleChange = (key, value) => {
		this.setState({ value })
	}

	render = () => {
		const { inputStyle, label, readOnly, placeholder } = this.props;
		const { matching, value } = this.state;
		const width = this.inputRef.current && this.inputRef.current.clientWidth;

		const dropOptions = (matching || []).map((a, i) => (
			<span
				className="searchableInput-dropdown-option"
				key={i}
				onClick={() => this.handleChange('', `${a.first_name} ${a.last_name}`)}
			>
				{`${a.first_name} ${a.last_name}`}
			</span>
		)) || [];

		const dropdown = (
			<div className="searchableInput-dropdown" style={{ width }}>
				{ dropOptions }
			</div>
		);

		return (
			<Input
				dataList={dropdown}
				inputRef={this.inputRef}
				inputStyle={inputStyle}
				label={label}
				list={`attendees-${label}`}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
			/>
		)
	}
}

export default SearchableInput;