import NotificationManager from 'react-notifications/lib/NotificationManager';
import '../styles/dropdown.scss';

const Dropdown = ({ choices, name, onChange, validate }) => {
	const options = choices.map(({ label, value }, i) => {
		if (!validate()) return'';
		return <option value={value} key={i}>{label}</option>
	});
	const handleOnChange = e => {
		const valid = validate();
		if (valid) onChange(name, e.target.value);
		else {
			e.preventDefault();
			NotificationManager.error('Please select a valid reviewee and assessor')
		}
	}
	return (
		<div className="dropdown-container">
			<select className="dropdown-selector" name={name} onChange={handleOnChange}>
				{options}
			</select>
		</div>
	);
}

export default Dropdown;