
const { NODE_ENV, PORT, REACT_APP_DEV_HOST } = process.env;
const isProd = NODE_ENV === 'production';
const staging = window.origin.includes('/staging.') ? 'staging.' : '';
const portOffset = window.location.port.toString().slice(1) || '101';

const CONFIG = {
  host: `http://${REACT_APP_DEV_HOST || 'localhost'}:${PORT}`,
  goServer: `http://${REACT_APP_DEV_HOST || 'localhost'}:8080`,
  server: `http://${REACT_APP_DEV_HOST || 'localhost'}:8${portOffset}`,
	REQ_VAL_START: 2
};

if (isProd) {
	CONFIG.host = 'https://aze-workshop-forms-d7001d5dc4ec.herokuapp.com';
  CONFIG.goServer = `https://${staging}server.bluebirdadmin.com`;
	CONFIG.server = 'https://aze-workshop-forms-d7001d5dc4ec.herokuapp.com';
}

export default CONFIG;
