import FormSection from './FormSection';
import '../styles/form.scss';

const Form = ({ content, initData, onSubmit }) => {
	const sections = content.map((section, idx) => {
		const fmtInitData = Object.keys(initData).reduce((acc, curr) => {
			if (curr.includes(section.label)) acc[curr] = initData[curr];
			return acc;
		}, {});
		return (
			<FormSection
				initData={fmtInitData}
				{...section}
				key={idx}
				onSubmit={onSubmit}
			/>
		);
	});
	return (
		<div className="form-container">
			{sections}
		</div>
	)
}

export default Form;
