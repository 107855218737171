// import { useEffect } from 'react';
import '../styles/radioButton.scss';

const RadioButton = ({ onSubmit, onChange, options, label, value }) => {
	const handleClick = (optionValue) => async () => {
		if (onChange) await onChange(optionValue)
		if (onSubmit) await onSubmit();
	};

	const createOption = (label, idx) => {
		const isSelected = label === value;
		const selectedClass = isSelected ? ' selected' : '';
		return (
			<div className="radiobutton-option" onClick={handleClick(label)} key={idx}>
				<div className={'radiobutton-select' + selectedClass}></div>
				<p className="radiobutton-label">{label}</p>
			</div>
		);
	};

	return (
		<div className="radiobutton-container">
			<p className='radiobutton-prompt'><b>{label}</b></p>
			<div className='radiobutton-container-options'>{options.map((option, idx) => createOption(option, idx))}</div>
		</div>
	);
}

export default RadioButton;
