import React from 'react';
import { FormRow } from './index';

const SectionHeader = ({ label, labelColor }) => {
	return label && <h2 style={{ backgroundColor: labelColor }} className={'form-section-header'}>{label}</h2>
}

const FormSection = props => {
	const { className, label, labelColor, rows, onSubmit } = props;
	const renderRows = rows && rows.map((row, idx) => {
		return (
			<FormRow
				label={row.label}
				labelTable={row.labelTable} 
				labelBullets={row.labelBullets}
				labelRight={row.labelRight}
				key={idx}
				section={label}
				type={row.type}
				onSubmit={onSubmit}
				options={row.options}
				name={row.name}
				readOnly={row.readOnly}
			/>
		);
	});

	let classN = 'form-section';
	if (className) classN += ` ${className}`;

	return (
		<div className={classN}>
			<SectionHeader label={label} labelColor={labelColor}/>
			{renderRows}
		</div>
	);
};

export default FormSection;
