import React, { Component } from 'react';

import { connect } from 'react-redux';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { GetURLParamValue, GoDataService } from '@blueprintnyc/bpnyc-common/ui_utils';
import { Header, Form } from './components';
import {
	FetchGet,
	FetchPost,
	FindScenarioData,
} from './helpers';
import { setData } from './redux/actions';

import rstForms from './rstContent.json';
import ripcForms from './ripcContent.json'
import config from './config';

import './index.scss'

const DataService = new GoDataService({ env: process.env });

const formContents = {
	"RST": rstForms,
	"RIPC": ripcForms,
}
const [_, track, workshop] = window.location.pathname.split('/');
const content = formContents[track];
class App extends Component {
	state = { 
		data: {},
		date: "",
		track,
		workshop,
	};

	componentDidMount = async () => {
		this.setState({ track, workshop});
		await this.whoAmI()
		await this.getFormData();
	}

	createForm = () => {
		const { workshop } = this.state;
		const { data } = this.props;
		const sectionData = FindScenarioData(data.sections, workshop);
		const formContent = content[workshop].form;
		const formProps = {
			workshop,
			content: formContent,
			initData: sectionData,
			onSubmit: this.submitScenario,
			setData: this.setMainState,
		}
		return <Form {...formProps} /> 
	}

	getFormData = async () => {
		const url = '/api/worksheet';
		console.log('sending to', url)
		const { me, workshop, track} = this.state;
		const { server } = config;
		const { data, err } = await FetchPost(server + url, { me, workshop, track });
		if (data) {
			this.props.setData(data)
		} else if (err) {
			console.error(err);
			NotificationManager.warning('No data found');
		} else {
			this.props.setData({ workshop, track, sections: {} });
		}
	}

	getRevieweeMetaData = async () => {
		const { reviewee } = this.state;
		const { goServer } = config;
		const eventID = GetURLParamValue('event');
		const url = `/api/metadata/${eventID}/${reviewee.id}`;
		if (reviewee.id) {
			try {
				const response = await FetchGet(goServer + url);
				let district = 'n/a';
				console.log(response)
				if (response.district) district = response.district;
				this.setState({ district });
			} catch (err) {
				console.error(err);
			}
		}
	}

	setMainState = (key, value) => {
		console.log(key, value)
		this.setState({ [key]: value });
	}

	submitScenario = async () => {
		console.log("ON SUBMIT")
		const { me, workshop, track } = this.state;
		const { data } = this.props;
		const { server } = config;
		const date = new Date();
		const submitData = { ...data, me, date, workshop, track};
		try {
			const { data, err } = await FetchPost(server + '/api/worksheet/submit', submitData);
			if (err) {
				console.error(err);
				NotificationManager.error(`Scenario submission failed`);
			}
		} catch (err) {
			console.error(err);
			NotificationManager.error(`Scenario submission failed`);
		}
	}

	whoAmI = async () => {
		const response = await DataService.getAttendee();
		const { id, email, first_name, last_name, meta_data } = response;
		const district = meta_data.district || "";
		this.setState({ me: { id, email, first_name, last_name, district } })
	}

	render = () => {
		const { me, workshop } = this.state;
		if(!content || !content[workshop]) return <h3>No content available</h3>
		return (
			<div className="worksheet-container">
				<Header
					me={me}
					title={content[workshop].title}
					titleColor={content[workshop].titleColor}
					instructions={content[workshop].instructions}
					workshop={workshop}
				/>
				{this.createForm()}
			</div>
		);
	};
}

const mapStateToProps = state => ({
	data: state.form
});
const mapDispatchToProps = dispatch => ({
	setData: data => dispatch(setData(data))
});

export default (() => connect(mapStateToProps, mapDispatchToProps)(App))();
