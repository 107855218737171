import { ReduceByKey } from '../../helpers';

export const DELETE_USER = 'DELETE_USER';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const SET_ME = 'SET_ME';
export const DELETE_ME = 'DELETE_ME';
export const RESET_USERS = 'RESET_USERS';

export const setUser = user => ({
	type: SET_USER,
	user
});

export const setUsers = users => ({
	type: SET_USERS,
	usersByID: (users || []).reduce(ReduceByKey('userid'), {})
});

export const deleteUser = userID => ({
	type: DELETE_USER,
	userID
});

export const setMe = me => ({
	type: SET_ME,
	me
});

export const deleteMe = () => ({
	type: DELETE_ME
});

export const resetUsers = () => ({
	type: RESET_USERS
});
