import { AutoSaveInput } from '@blueprintnyc/bpnyc-common';


import '../styles/textinput.scss';

const TextInput = ({
	label,
	onChange,
	onSubmit,
	value,
	name,
	readOnly
}) => {

	return (
		<div className={'textinput'}>
			<p><b>{label}</b></p>
			{ readOnly ? <input className={"bpnyc-controlledInput"} readOnly={true} value={name}/> : <AutoSaveInput onChange={onChange} onSubmit={onSubmit} value={value}/> }
		</div>
	)
};

export default TextInput;